<template>
    <div class="wrapper__img">
        <img class="wrapper__img__bg" src="../../assets/pic_login_bg.png" alt=""/>
        <img
            class="wrapper__img__title"
            src="../../assets/pic_login_title.png"
            alt=""
        />
    </div>
    <div class="wrapper">
        <div class="wrapper__input">
            <div class="wrapper__input__title">账号</div>
            <van-field v-model="account" :border="true" placeholder="请输入账号"/>
        </div>
        <div class="wrapper__input">
            <div class="wrapper__input__title">密码</div>
            <van-field v-model="password" type="password" placeholder="请输入密码"/>
        </div>
    </div>

    <div class="button__login">
        <van-button
            type="primary"
            size="normal"
            color="#4377FF"
            @click="loginBtnClick"
            block
            round
        >
            登录
        </van-button>
    </div>
</template>

<script>
import { useRouter } from "vue-router"
import { useStore } from "vuex"
import { ref } from "vue"
import { Toast } from "vant"

import { post } from "@/request/request"

export default {
  name: "Login",
  setup () {
    const {
      account,
      password,
      loginBtnClick
    } = loginEffect()
    return {
      account,
      password,
      loginBtnClick
    }
  }
}

/*
*  登录逻辑
* */
const loginEffect = () => {
  const router = useRouter()
  const account = ref("")
  const password = ref("")
  const store = useStore()

  const loginBtnClick = () => {
    if (account.value.length === 0 || password.value.length === 0) {
      Toast("账号或密码不能为空")
      return
    }
    Toast.loading({ duration: 10000 })
    try {
      post("/api/user/login", {
        account: account.value,
        password: password.value
      }).then((data) => {
        Toast.success("登录成功")
        store.commit("loginSuccess", { user: data.userinfo })
        setTimeout(() => {
          router.replace({
            name: "index"
          })
        }, 1000)
      })
    } catch (e) {
      Toast(e)
    }
  }
  return {
    account,
    password,
    loginBtnClick
  }
}

</script>

<style lang="scss" scoped>
.wrapper__img {
    position: relative;
    height: 1.8rem;

    &__bg {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
    }

    &__title {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 0.32rem;
        width: auto;
    }
}

.wrapper {
    margin-top: 0.6rem;

    &__input {
        margin: 0 0.2rem 0.2rem 0.2rem;
        border-bottom: 0.01rem solid #f0f0f0;

        &__title {
            font-size: 0.17rem;
            margin-left: 0.16rem;
            margin-bottom: 0.05rem;
        }
    }
}

.button__login {
    margin: 0.6rem 0.2rem;
}
</style>
